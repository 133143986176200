import { ServicioService } from '../servicio/servicio.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  sesion: any = {};
  loading = false;

  alerta: any;
  tipoAlerta: any;

  constructor(private Api: ServicioService, private router: Router) { }

  ngOnInit() {
    const el = document.getElementById('nb-global-spinner');
    if (el) {
      el.style.display = 'none';
    }
    this.logout();
  }

  loginUser(event) {
    event.preventDefault();
    const target = event.target;
    const username = target.querySelector('#username').value;
    const password = target.querySelector('#password').value;
    if ( username == '' || username == undefined || password == '' || password == undefined) {
            this.alerta = 'Debe ingresar usuario y contraseña';
            this.tipoAlerta = 'danger';
    } else {
      this.loading = true;
      const obj: any = {
                          usuario: username,
                          password: password,
                       };
      this.Api.postLoginEmpresa(obj).subscribe(data => {
        const code = data['errorCode'];
        console.log(code);
        if (code === 0) {
            this.alerta = 'Datos verificados';
            this.tipoAlerta = 'success';
            if (data['msg']['idTipoUsuario'] == 1) {
              this.router.navigateByUrl('pages/iot-dashboard');
            } else {
              this.router.navigateByUrl('pages/welcome');
            }
            this.Api.setUsuario(data['msg']);
        } else {
            this.alerta = 'Ocurrio un error, no pudimos validar tus datos';
            this.tipoAlerta = 'danger';
        }
        this.loading = false;
        this.Api.guardarSesion(data['msg']);
        this.Api.guardarPrivilegios(data['privileges']);
      });
    }
  }

  logout() {
    sessionStorage.clear();
  }

}

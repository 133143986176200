import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';




@Injectable({
  providedIn: 'root',
})
export class ServicioService {

  // Desarrollo
  // API_Uri = 'http://localhost/angular/tupasaje-empresa/API_REST';
  // API_Uri = 'http://localhost:8888/proyectosGIT/tupasaje-empresa/API_REST';

  // Produccion
  // API_Uri = 'https://empresa.tupasaje.bo/API_REST'; 
  API_Uri = 'https://tupasajebus.clanbolivia.com/API_REST_emp/';

  sesion = [];
  privilegios = [];
  idPedido: any = 0;

  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  usuario: any;

  constructor(
    private http: HttpClient,
    private toastrService: NbToastrService,
  ) {
  }

  guardarSesion(sesion) {
    this.sesion = sesion;
    sessionStorage.setItem('currentUser', JSON.stringify(sesion));
    // localStorage.clear();
  }

  recuperarSesion() {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  guardarPrivilegios(privilegios) {
    this.privilegios = privilegios;
    sessionStorage.setItem('privileges', JSON.stringify(privilegios));
  }

  recuperarPrivilegios() {
    return JSON.parse(sessionStorage.getItem('privileges'));
  }

  getPrivilegios(rol:any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getPrivilegios/${rol}/${idtoken}/${token}`);
  }

  setIdPedido(idPedido) {
    this.idPedido = idPedido;
  }

  setUltimaRuta(id){
    localStorage.setItem('idUltimaRuta', id);
  }
  getUltimaRuta(){
    return localStorage.getItem('idUltimaRuta')? localStorage.getItem('idUltimaRuta'):0;
  }

  getIdPedido() {
    return this.idPedido;
  }

  // TP00EMP - POST - LOGIN DE EMPRESA
  postLoginEmpresa(obj: any) {
    return this.http.post(`${this.API_Uri}/postLoginEmpresa`, obj);
  }

  // TP01EMP - POST - LOGIN USUARIO DE EMPRESA
  postLoginUsuarioEmpresa(obj: any) {
    return this.http.post(`${this.API_Uri}/postLoginUsuarioEmpresa`, obj);
  }

  // TP02EMP - GET - OBTENER LISTA DE USUARIOS
  getListaUsuarios(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaUsuarios/${idtoken}/${token}`);
  }
  
  getListaPropietarios(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaPropietarios/${idtoken}/${token}`);
  }

  // TP03EMP - POST - INSERTAR USUARIO
  postInsertarUsuario(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarUsuario`, obj);
  }

  // TP04EMP - POST - MODIFICAR USUARIO
  postModificarUsuario(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarUsuario`, obj);
  }

  // TP05EMP - POST - DESHABILITAR/HABILITAR USUARIO
  postDeshabilitarUsuario(obj: any) {
    return this.http.post(`${this.API_Uri}/postDeshabilitarUsuario`, obj);
  }

  // TP06EMP - POST - RESETEAR PASSWORD USUARIO
  postResetearPasswordUsuario(obj: any) {
    return this.http.post(`${this.API_Uri}/postResetearPasswordUsuario`, obj);
  }

  // TP07EMP - POST - MODIFICAR PERFIL EMPRESA
  postModificarPerfil(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarPerfil`, obj);
  }
  
  // TP07EMP - POST - MODIFICAR PERFIL EMPRESA
  postModificarPerfilEmpresa(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarPerfilEmpresa`, obj);
  }

  // TP08EMP - GET - OBTENER LISTA DE BUSES
  getListaBuses(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaBuses/${idtoken}/${token}`);
  }

  // TP09EMP - POST - INSERTAR BUS
  postInsertarBus(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarBus`, obj);
  }

  // TP10EMP - POST - MODIFICAR BUS
  postModificarBus(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarBus`, obj);
  }


  deleteBus(id:any, idtoken: any, token: any) {
    return this.http.delete(`${this.API_Uri}/deleteBus/${id}/${idtoken}/${token}`);
  }

  // TP11EMP - GET - OBTENER LISTA DE SERVICIOS
  getListaServicios(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaServicios/${idtoken}/${token}`);
  }

  // TP12EMP - GET - OBTENER LISTA DE TIPOS DE BUS
  getListaTipoBus(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaTipoBus/${idtoken}/${token}`);
  }

  // TP13EMP - GET - OBTENER LISTA DE VIAJES
  getListaViajes(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaViajes/${idtoken}/${token}`);
  }

  // TP14EMP - POST - INSERTAR VIAJE
  postInsertarViaje(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarViaje`, obj);
  }

  // TP15EMP - GET - OBTENER LISTA DE RUTAS
  getListaRutas(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaRutas/${idtoken}/${token}`);
  }
  
  getListaRutasEmpresa(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaRutasEmpresa/${idtoken}/${token}`);
  }

  editarRutasEmpresa(idtoken: any, token: any, obj: any) {
    return this.http.post(`${this.API_Uri}/editarRutasEmpresa/${idtoken}/${token}`, obj);
  }

  getRutasEmpresa(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getRutasEmpresa/${idtoken}/${token}`);
  }

  // TP16EMP - POST - INSERTAR VENTA
  postInsertarVenta(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarVenta`, obj);
  }

  confirmarVenta(idtoken: any, token: any, idCompra: any, facturacion: any, tipoUsuario: any, idUsuario: any, nombre: any, nit: any) {
    return this.http.get(`${this.API_Uri}/confirmarVenta/${idtoken}/${token}/${idCompra}/${facturacion}/${tipoUsuario}/${idUsuario}/${nombre}/${nit}`);
  }

  postActualizarAsiento(obj: any) {
    return this.http.post(`${this.API_Uri}/postActualizarAsiento`, obj);
  }
  postActualizarBoletos(obj: any) {
    return this.http.post(`${this.API_Uri}/postActualizarBoletos`, obj);
  }
  postCambioViaje(obj: any) {
    return this.http.post(`${this.API_Uri}/postCambioViaje`, obj);
  }
  postCambioVendedor(obj: any) {
    return this.http.post(`${this.API_Uri}/postCambioVendedor`, obj);
  }

  // TP17EMP - GET - OBTENER LISTA DE DIRECCIONES DE EMPRESA
  getListaDireccionesEmpresa(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaDireccionesEmpresa/${idtoken}/${token}`);
  }

  // TP18EMP - POST - INSERTAR DIRECCION
  postInsertarDireccion(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarDireccion`, obj);
  }

  // TP19EMP - POST - MODIFICAR DIRECCION
  postModificarDireccion(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarDireccion`, obj);
  }

  // TP20EMP - POST - DESHABILITAR/HABILITAR DIRECCION
  postDeshabilitarDireccion(obj: any) {
    return this.http.post(`${this.API_Uri}/postDeshabilitarDireccion`, obj);
  }

  // TP21EMP - GET - OBTENER LISTA DE CIUDADES HABILITADAS
  getListaCiudadesHabilitadas(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaCiudadesHabilitadas/${idtoken}/${token}`);
  }

  // TP22EMP - GET - OBTENER LISTA DE FOTOS DE EMPRESA
  getListaFotosEmpresa(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaFotosEmpresa/${idtoken}/${token}`);
  }

  // TP23EMP - POST - INSERTAR FOTO DE EMPRESA
  postInsertarFotoEmpresa(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarFotoEmpresa`, obj);
  }

  // TP24EMP - POST - ELIMINAR FOTO DE EMPRESA
  postEliminarFotoEmpresa(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarFotoEmpresa`, obj);
  }

  // TP25EMP - GET - OBTENER LISTA DE VIAJES PENDIENTES POR BUS
  getListaViajesBus(idtoken: any, token: any, idBus: any) {
    return this.http.get(`${this.API_Uri}/getListaViajesBus/${idtoken}/${token}/${idBus}`);
  }

  // TP26EMP - GET - OBTENER LISTA DE VIAJES PENDIENTES POR RUTA
  getListaViajesRuta(idtoken: any, token: any, idRuta: any) {
    return this.http.get(`${this.API_Uri}/getListaViajesRuta/${idtoken}/${token}/${idRuta}`);
  }

  // TP27EMP - POST - BUSCAR VIAJE
  postBuscarViaje(obj: any) {
    return this.http.post(`${this.API_Uri}/postBuscarViaje`, obj);
  }

  // TP28EMP - GET - OBTENER ARRAY DE ASIENTOS
  getArrayAsientos(idtoken: any, token: any, idViaje: any) {
    return this.http.get(`${this.API_Uri}/getArrayAsientos/${idtoken}/${token}/${idViaje}`);
  }

  // TP29EMP - GET - OBTENER LISTA DE COMPRAS (ÚLTIMAS 20)
  obtenerVentas2(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/obtenerVentas/${idtoken}/${token}`);
  }
  obtenerVentas(idtoken: any, token: any, fi: any, ff: any, tipoFecha: any) {
    return this.http.get(`${this.API_Uri}/obtenerVentas/${idtoken}/${token}/${fi}/${ff}/${tipoFecha}`);
  }
  
  // TP29EMP - GET - OBTENER LISTA DE COMPRAS (ÚLTIMAS 20)
  obtenerVentasFiltradas(idtoken: any, token: any, idBus: any, idPropietario: any, idRuta: any, idVendedor: any, puntoVenta: any, fi: any, ff: any) {
    return this.http.get(`${this.API_Uri}/obtenerVentasFiltradas/${idtoken}/${token}/${idBus}/${idPropietario}/${idRuta}/${idVendedor}/${puntoVenta}/${fi}/${ff}`);
  }

  // TP30EMP - GET - OBTENER LISTA DE COMPRAS POR NOMBRE DE CLIENTE
  getListaComprasCliente(idtoken: any, token: any, texto: any) {
    return this.http.get(`${this.API_Uri}/getListaComprasCliente/${idtoken}/${token}/${texto}`);
  }

  // TP31EMP - GET - OBTENER LISTA DE COMPRAS POR RANGO DE FECHAS
  getListaComprasRango(idtoken: any, token: any, fechaInicio: any, fechaFin: any) {
    return this.http.get(`${this.API_Uri}/getListaComprasRango/${idtoken}/${token}/${fechaInicio}/${fechaFin}`);
  }

  // TP32EMP - GET - OBTENER LISTA DE DETALLE DE COMPRA
  getListaDetalleCompra(idtoken: any, token: any, idCompra: any) {
    return this.http.get(`${this.API_Uri}/getListaDetalleCompra/${idtoken}/${token}/${idCompra}`);
  }

  getDetalleCompra(idtoken: any, token: any, idCompra: any) {
    return this.http.get(`${this.API_Uri}/getDetalleCompra/${idtoken}/${token}/${idCompra}`);
  }

  // TP33EMP - GET - OBTENER INDICADORES
  getIndicadores(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getIndicadores/${idtoken}/${token}`);
  }

  // TP34EMP - GET - OBTENER INDICADORES POR RANGO DE FECHAS
  getIndicadoresRango(idtoken: any, token: any, month: any, year: any) {
    return this.http.get(`${this.API_Uri}/getIndicadoresRango/${idtoken}/${token}/${month}/${year}`);
  }

  // TP35EMP - GET - OBTENER COMISION POR RANGO DE FECHAS
  getListaComisiones(idtoken: any, token: any, fechaInicio: any, fechaFin: any) {
    return this.http.get(`${this.API_Uri}/getListaComisiones/${idtoken}/${token}/${fechaInicio}/${fechaFin}`);
  }

  // TP36EMP - GET - OBTENER LISTA DE RUTAS CON VIAJES DISPONIBLES
  getListaRutasViaje(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaRutasViaje/${idtoken}/${token}`);
  }

  // TP37EMP
  postInsertarViajeExcel(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarViajeExcel` , obj);
  }

  // TP38EMP - POST - MODIFICAR PASSWORD EMPRESA O USUARIO
  postModificarPasswordEmpresa(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarPasswordEmpresa` , obj);
  }

  // TP39EMP - POST - INSERTAR ASIENTO BLOQUEADO
  postInsertarAsientoBloqueado(obj: any) {
    return this.http.post(`${this.API_Uri}/postInsertarAsientoBloqueado` , obj);
  }

  // TP40EMP - POST - ELIMINAR ASIENTO BLOQUEADO
  postEliminarAsientoBloqueado(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarAsientoBloqueado` , obj);
  }

  // TP41EMP - GET - OBTENER LISTA DE ASIENTOS BLOQUEADOS
  getListaAsientosBloqueados(idtoken: any, token: any, idBus: any) {
    return this.http.get(`${this.API_Uri}/getListaAsientosBloqueados/${idtoken}/${token}/${idBus}`);
  }

  // TP42EMP - GET - OBTENER LISTA DE COMPRAS (ÚLTIMAS 20) USUARIO
  getListaCompras20Usuario(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaCompras20Usuario/${idtoken}/${token}`);
  }

  // TP43EMP - GET - OBTENER LISTA DE COMPRAS POR NOMBRE DE CLIENTE USUARIO
  getListaComprasClienteUsuario(idtoken: any, token: any, texto: any) {
    return this.http.get(`${this.API_Uri}/getListaComprasClienteUsuario/${idtoken}/${token}/${texto}`);
  }

  // TP44EMP - GET - OBTENER LISTA DE COMPRAS POR RANGO DE FECHAS USUARIO
  getListaComprasRangoUsuario(idtoken: any, token: any, fechaInicio: any, fechaFin: any) {
    return this.http.get(`${this.API_Uri}/getListaComprasRangoUsuario/${idtoken}/${token}/${fechaInicio}/${fechaFin}`);
  }

  // TP45EMP - GET - OBTENER LISTA DE DETALLE DE COMPRA USUARIO
  getListaDetalleCompraUsuario(idtoken: any, token: any, idCompra: any) {
    return this.http.get(`${this.API_Uri}/getListaDetalleCompraUsuario/${idtoken}/${token}/${idCompra}`);
  }

  // TP46EMP - GET - OBTENER LISTA DE RUTAS CON VIAJES DISPONIBLES USUARIO
  getListaRutasViajeUsuario(idtoken: any, token: any, idEmpresa: any) {
    return this.http.get(`${this.API_Uri}/getListaRutasViajeUsuario/${idtoken}/${token}/${idEmpresa}`);
  }

  // TP47EMP - GET - OBTENER LISTA DE VIAJES SIN VENTAS
  obtenerViajesEmpresa(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/obtenerViajesEmpresa/${idtoken}/${token}`);
  }

  obtenerSalidas(idtoken: any, token: any, idterminal: any, idTipoUsuario) {
    return this.http.get(`${this.API_Uri}/obtenerSalidas/${idtoken}/${token}/${idterminal}/${idTipoUsuario}`);
  }
  obtenerBusesConCarga(idtoken: any, token: any, idEmpresa) {
    return this.http.get(`${this.API_Uri}/obtenerBusesConCarga/${idtoken}/${token}/${idEmpresa}`);
  }
  getCargasPorViaje(idViaje) {
    return this.http.get(`${this.API_Uri}/getCargasPorViaje/${idViaje}`);
  }
  
  obtenerLlegadas(idtoken: any, token: any, idterminal: any, idTipoUsuario) {
    return this.http.get(`${this.API_Uri}/obtenerLlegadas/${idtoken}/${token}/${idterminal}/${idTipoUsuario}`);
  }

  obtenerPasajerosViaje(idtoken: any, token: any, idviaje: any) {
    return this.http.get(`${this.API_Uri}/obtenerPasajerosViaje/${idtoken}/${token}/${idviaje}`);
  }
  
  obtenerCargasViaje(idtoken: any, token: any, idviaje: any) {
    return this.http.get(`${this.API_Uri}/obtenerCargasViaje/${idtoken}/${token}/${idviaje}`);
  }
  getIngresosCargaPorViaje(idtoken: any, token: any, idviaje: any) {
    return this.http.get(`${this.API_Uri}/getIngresosCargaPorViaje/${idtoken}/${token}/${idviaje}`);
  }
  getDetalleViaje( idviaje: any) {
    return this.http.get(`${this.API_Uri}/getDetalleViaje/${idviaje}`);
  }
  
  // TP48EMP - POST - MODIFICAR VIAJE
  postModificarViaje(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarViaje` , obj);
  }

  // TP49EMP - POST - ELIMINAR VIAJE
  postEliminarViaje(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarViaje` , obj);
  }

  cambiarEstadoViaje(idtoken: any, token: any, idviaje: any, estado: any) {
    return this.http.get(`${this.API_Uri}/cambiarEstadoViaje/${idtoken}/${token}/${idviaje}/${estado}`);
  }

  postModificarEstadoCompra(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarEstadoCompra` , obj);
  }


  ///// carga
  getAllcarga(idtoken: any, token: any, fi: any, ff: any) {
    return this.http.get(`${this.API_Uri}/getAllcarga/${idtoken}/${token}/${fi}/${ff}`);
  }
  
  getAllCargaPendiente(tipoCarga: any, idRuta: any, idtoken: any, token: any, fi: any, ff: any) {
    console.log("tipoCarga", idRuta);
    return this.http.get(`${this.API_Uri}/getAllCargaPendiente/${tipoCarga}/${idRuta}/${idtoken}/${token}/${fi}/${ff}`);
  }
  
  getCargasRegistradas(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getCargasRegistradas/${idtoken}/${token}`);
  }
  getCargasPorEstado(idtoken: any, token: any, idEstado: any, idEmpresa: any) {
    return this.http.get(`${this.API_Uri}/getCargasPorEstado/${idtoken}/${token}/${idEstado}/${idEmpresa}`);
  }

  postCargasEnTransito(obj: any) {
    console.log(obj);
    return this.http.post(`${this.API_Uri}/postCargasEnTransito`, obj);
  }
  postCargasEnDestino(obj: any) {
    console.log(obj);
    return this.http.post(`${this.API_Uri}/postCargasEnDestino`, obj);
  }

  getAllCargaPagadasPendiente(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllCargaPagadasPendiente/${idtoken}/${token}`);
  }

  getAllCargaPorCobrarPendiente(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getAllCargaPorCobrarPendiente/${idtoken}/${token}`);
  }

  getCargasRango(idtoken: any, token: any, fechaInicio: any, fechaFin: any) {
    return this.http.get(`${this.API_Uri}/getCargasRango/${idtoken}/${token}/${fechaInicio}/${fechaFin}`);
  }

  getOnecarga(id:any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getOnecarga/${id}/${idtoken}/${token}`);
  }

  postInsertarcarga(obj:any) {
      return this.http.post(`${this.API_Uri}/postInsertarcarga` , obj);
  }

  postModificarcarga(obj:any) {
      return this.http.post(`${this.API_Uri}/postModificarcarga` , obj);
  }

  postModificarEstadocarga(obj:any) {
      return this.http.post(`${this.API_Uri}/postModificarEstadocarga` , obj);
  }

  marcarCargaEnDestino(obj:any) {
    return this.http.put(`${this.API_Uri}/marcarCargaEnDestino` , obj);
  }

  marcarCargaEnTransito(obj:any) {
    return this.http.put(`${this.API_Uri}/marcarCargaEnTransito` , obj);
  }

  marcarCargaEntregada(obj:any) {
    return this.http.put(`${this.API_Uri}/marcarCargaEntregada` , obj);
  }

  postEliminarcarga(obj:any) {
      return this.http.post(`${this.API_Uri}/postEliminarcarga` , obj);
  }

  postInsertarBitacora(obj:any) {
    return this.http.post(`${this.API_Uri}/postInsertarBitacora` , obj);
  }

  verificarGuiaAleatoriaNoRepetida(idtoken: any, token: any, guia: any) {
    return this.http.get(`${this.API_Uri}/verificarGuiaAleatoriaNoRepetida/${idtoken}/${token}/${guia}`)
  }

  obtenerClientesCargas(idtoken: any, token: any, coincidencia: any) {
    return this.http.get(`${this.API_Uri}/obtenerClientesCargas/${idtoken}/${token}/${coincidencia}`)
  }
  
  obtenerTerminales(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/obtenerTerminales/${idtoken}/${token}`)
  }

  // Conductores
  getListaConductores(idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getListaConductores/${idtoken}/${token}`);
  }
  getConductor(id:any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getConductor/${id}/${idtoken}/${token}`);
  }
  postInsertarConductor(obj:any) {
    return this.http.post(`${this.API_Uri}/postInsertarConductor` , obj);
  }
  putModificarConductor(obj:any) {
      return this.http.put(`${this.API_Uri}/putModificarConductor` , obj);
  }
  deleteConductor(id:any, idtoken: any, token: any) {
    return this.http.delete(`${this.API_Uri}/deleteConductor/${id}/${idtoken}/${token}`);
  }

  // Roles y privilegios
  getRolesEmpresa(id: any) {
    return this.http.get(`${this.API_Uri}/getRolesEmpresa/${id}`);
  }

  getPrivilegiosEmpresa() {
    return this.http.get(`${this.API_Uri}/getPrivilegiosEmpresa`);
  }

  getRol(id:any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/getRol/${id}/${idtoken}/${token}`);
  }
  postInsertarRol(obj:any) {
    return this.http.post(`${this.API_Uri}/postInsertarRol` , obj);
  }
  putModificarRol(obj:any) {
      return this.http.put(`${this.API_Uri}/putModificarRol` , obj);
  }
  deleteRol(id:any, idtoken: any, token: any) {
    return this.http.delete(`${this.API_Uri}/deleteRol/${id}/${idtoken}/${token}`);
  }

  obtenerHorasRuta(id:any) {
    return this.http.get(`${this.API_Uri}/obtenerHorasRuta/${id}`);
  }

  // Reportes
  obtenerReporteConductores(id:any, idtoken: any, token: any, fi:any, ff:any) {
    return this.http.get(`${this.API_Uri}/obtenerReporteConductores/${id}/${idtoken}/${token}/${fi}/${ff}`);
  }
  obtenerReporteBuses(idBus:any, idPropietario:any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/obtenerReporteBuses/${idBus}/${idPropietario}/${idtoken}/${token}`);
  }
  obtenerReportePasajeros(id:any, idtoken: any, token: any, fi:any, ff:any) {
    return this.http.get(`${this.API_Uri}/obtenerReportePasajeros/${id}/${idtoken}/${token}/${fi}/${ff}`);
  }

  // Cambio de bus
  cambiarBus(idViaje:any, idNuevoBus: any, idViejoBus: any, idtoken: any, token: any) {
    return this.http.get(`${this.API_Uri}/cambiarBus/${idViaje}/${idNuevoBus}/${idViejoBus}/${idtoken}/${token}`);
  }

  cambiarHoraSalida(obj: any) {
    return this.http.post(`${this.API_Uri}/cambiarHoraSalida` , obj);
  }
  
  cambiarHoraLlegada(obj: any) {
    return this.http.post(`${this.API_Uri}/cambiarHoraLlegada` , obj);
  }
  //////////////////////
  //gastos


  postBuscarViajePorFecha(obj: any) {
    return this.http.post(`${this.API_Uri}/postBuscarViajePorFecha`, obj);
  }
  getGastosTipo() {
    return this.http.get(`${this.API_Uri}/getGastosTipo`);
  }
  postGasto(obj: any) {
    return this.http.post(`${this.API_Uri}/postGasto`, obj);
  }
  postModificarGasto(obj: any) {
    return this.http.post(`${this.API_Uri}/postModificarGasto`, obj);
  }
  postEliminarGasto(obj: any) {
    return this.http.post(`${this.API_Uri}/postEliminarGasto`, obj);
  }
  getGastosPorViaje(obj: any) {
    return this.http.post(`${this.API_Uri}/getGastosPorViaje`, obj);
  }
  getIngresosPorViaje(obj: any) {
    return this.http.post(`${this.API_Uri}/getIngresosPorViaje`, obj);
  }

  ////////////////////////////////////////////////////////

  // reportes


  getAllreporte001(idtoken: any, token: any, fi: any, ff: any) {
    return this.http.get(`${this.API_Uri}/getAllreporte001/${idtoken}/${token}/${fi}/${ff}`);
  }

  // local

  setUsuario(obj) {
    this.usuario = obj;
  }
  getUsuario() {
    return this.usuario;
  }

  showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false
    };
    const titleContent = title ? `${title}` : "";

    this.toastrService.show(body, `${titleContent}`, config);
  }
}


    <nb-card>
      <nb-card-header>Detalle de Compra: {{boleto.codigo}}</nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="label">Cliente:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.cliente}}</label>
            </div>

            <div class="form-group">
              <label class="label">Fecha/hora de compra:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.fechaReg | date: 'dd/MM/yyyy'}} {{boleto.fechaReg | date: 'HH:mm'}}</label>
            </div>

            <!-- <div class="form-group">
              <label class="label">Hora de compra:&nbsp;</label>
              <label class="label" style="color: black;"></label>
            </div> -->

            <!--<div class="form-group">
              <label class="label">Detalle:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.detalle}}</label>
            </div>-->
            <div class="form-group">
              <label class="label" >Boletos:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.cantidad}}</label>
            </div>

            <div class="form-group">
              <label class="label" >Total:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.precio}} Bs.</label>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="label" >NIT:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.nit}}</label>
            </div>

            <div class="form-group">
              <label class="label" >Razón social:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.razonSocial}}</label>
            </div>

            <div class="form-group">
              <label class="label" >Forma de pago:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.formaPago}}</label>
            </div>

            <div class="form-group">
              <label class="label" >Estado:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.estadoCompra}}</label>
            </div>
            <!--<div class="form-group">
              <label class="label" >Fecha de entrega:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.fechaEstimada}}</label>
            </div>-->

            <!--<div class="form-group">
              <label class="label">Hora de entrega:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.horaEstimada}}</label>
            </div>

            <div class="form-group">
              <label class="label">Precio de transporte:&nbsp;</label>
              <label class="label" style="color: black;">{{boleto.precioTransporte}} Bs.</label>
            </div>-->
          </div>
        </div></nb-card-body>
      <nb-card-footer>
        <div class="row">
          <div class="col-md-6">
            <!-- <button nbButton >Cerrar</button> -->

          </div>
          <div style="text-align: right;" class="col-md-6" *ngIf="boleto.estado == 2">
            <button nbButton (click)="guardar()" status="warning">Confirmar</button>

          </div>
        </div>
          
      </nb-card-footer>
    </nb-card>


import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Creado por <b><a href="https://tupasaje.bo" target="_blank">Tu Pasaje</a></b> 2022</span>

    <div class="socials">
      <!-- <a href="#" target="_blank" class="ion ion-social-facebook"></a> -->
    </div>
  `,
})
export class FooterComponent {
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSearchService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { ServicioService } from '../../../servicio/servicio.service';
import { NbDialogService } from '@nebular/theme';
import { ModalFormComponent } from '../../../pages/modal-form/modal-form.component';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Cambiar contraseña' }, { title: 'Salir' } ];
  sesion: any = {};
  logoEmpresa: string = "../../../../assets/images/logo-v2.png";

  constructor(private servicio: ServicioService,
              private rou: Router,
              private sidebarService: NbSidebarService,
              private dialogService: NbDialogService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService
              , private searchService: NbSearchService) {


                this.searchService.onSearchSubmit()
      .subscribe((data: any) => {
        let idPedido = 0;
        idPedido = data.term;
    this.servicio.setIdPedido(idPedido);
    this.rou.navigateByUrl('pages/detalle-boleto');

      })
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.user = this.servicio.getUsuario();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title == 'Salir') {
          this.rou.navigate(['/login']);
        } else {
          this.open();
        }
      });
      this.sesion = this.servicio.recuperarSesion();
      if (this.sesion.personalizacion == "1") {
        this.logoEmpresa = this.sesion.logo;
      }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  open() {
      this.dialogService.open(ModalFormComponent);
  }

  
}

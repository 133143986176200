import { Component, OnInit, Input } from '@angular/core';
import { title } from 'process';
import { NbThemeService, NbDialogService, NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-boleto',
  templateUrl: './boleto.component.html',
  styleUrls: ['./boleto.component.scss']
})
export class BoletoComponent implements OnInit {

  // @Input = title;
  @Input () boleto : any;

  constructor(
    protected dialogRef: NbDialogRef<any>

  ) { }

  ngOnInit(): void {

    console.log(this.boleto);
  }

  guardar(){

    this.dialogRef.close(this.boleto);

  }

}

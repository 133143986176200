import { Component } from '@angular/core';
import { ServicioService } from 'app/servicio/servicio.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive [style.background]="color">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  color = "#FF902C";
  sesion: any = {};
  constructor(private servicio: ServicioService) {
    this.sesion = this.servicio.recuperarSesion();
    if (this.sesion.personalizacion == "1") {
      this.color = this.sesion.color;
    }
  }
}

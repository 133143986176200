<!--<div style="background-color: turquoise">-->
  <link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
  <script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
  <script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
  <!------ Include the above in your HEAD tag ---------->

  <div class="wrapper fadeInDown">
    <div id="formContent" style="background: #FF902C;">
      <!-- Tabs Titles -->

      <!-- Icon -->
      <!--<div class="fadeIn first">
        <img src="http://danielzawadzki.com/codepen/01/icon.svg" id="icon" alt="User Icon" />
      </div>-->
      <div class="logo fadeIn first">
          <div>
            <img src="../../assets/images/isoLogoNormal.png" width="200px" />
          </div>
      </div>

      <!-- Login Form -->
      <form method="post" (submit)="loginUser($event)">
        <input type="text" class="fadeIn second" name="username" placeholder="Usuario" id="username">
        <input type="password" class="fadeIn third" name="password" placeholder="Password" id="password">

        <nb-alert status="{{tipoAlerta}}"> 
          {{alerta}}
        </nb-alert>  

        <!--<input type="submit"   class="fadeIn fourth" value="Ingresar">-->

          <button [nbSpinner]="loading" nbSpinnerStatus="success" nbButton type="submit" status="primary" style="color: #ffce5a;
                background: black;
                margin: 10px;
                border-radius: 5px;
                padding: 10px;
                width: 200px;" value="Ingresar">
            Ingresar
          </button>
      </form>

    </div>
  </div>
<!--<div>
  <form class="box" method="post" (submit)="loginUser($event)">
      <h1>Login</h1>
      <input type="text" name="username" placeholder="Usuario" id="username">
      <input type="password" name="password" placeholder="Password" id="password">
      <input type="submit" name="" value="Login">
    </form>
</div>-->

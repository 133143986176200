<nb-card [nbSpinner]="loading" nbSpinnerStatus="warning">
  <nb-card-header>Cambiar contraseña</nb-card-header>
  <nb-card-body>
    <label class="label">Contraseña actual: &nbsp; </label>
    <input type="password" style="margin-top: 12px;" nbInput fullWidth placeholder="Contraseña actual" [(ngModel)]="tableS.passwordActInput"><br>
    <label class="label">Contraseña nueva: &nbsp; </label>
    <input type="password" style="margin-top: 12px;" nbInput fullWidth placeholder="Contraseña nueva" [(ngModel)]="tableS.passNew"><br>
    <label class="label">Repita la contraseña nueva: &nbsp; </label>
    <input type="password" style="margin-top: 12px;" nbInput fullWidth placeholder="Repita la contraseña nueva" [(ngModel)]="tableS.passNew2"><br>
  </nb-card-body>
  <nb-card-footer>
    <button status="danger" style="margin-right: 20px; width: 100px;" nbButton (click)="cancel()">Cancelar</button>
    <button status="success" style="width: 100px;" nbButton (click)="submit()">Guardar</button>
  </nb-card-footer>
</nb-card>
